import React, {useState} from 'react';
import '../../assets/css/global.scss'
import logo from "../../assets/img/logo.svg";
import {Link} from "react-router-dom";


const Header: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const closeMenu = () => setIsOpen(false)
    return (
        <header>
            <div className="wrap">
                <Link to={"/home"}>
                    <img
                        src={logo} className="logo"
                        alt="Modus Super Series"
                    />
                </Link>
                <i onClick={() => setIsOpen(!isOpen)} className="far fa-bars"/>
                {isOpen && <ul className="navMenu sequel">
                    <li>
                        <Link onClick={closeMenu} to={"/results"}>Results & Fixtures</Link>
                    </li>
                    <li>
                        <Link onClick={closeMenu} to={"/news"}>Latest News</Link>
                    </li>
                    <li>
                        <Link onClick={closeMenu} to={'/about'}>About</Link>
                    </li>
                    <li>
                        <Link onClick={closeMenu} to={'/honours'}>Honours</Link>
                    </li>
                    <li>
                        <a onClick={closeMenu} href="https://dartshop.tv/collections/modus-super-series" target="_blank">Tickets</a>
                    </li>
                </ul>}
            </div>
        </header>
    );
};

export default Header;
